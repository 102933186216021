import { ContentCopy, Refresh, Share } from '@mui/icons-material'
import { T } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { useWindowDimensions } from 'components/hooks/window'
import { SocialShare, ViewContext } from 'components/lib'
import { detectRtlDirection } from 'helpers'
import { CopyToClipBoard } from 'helpers/clipboard'
import { useCallback, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Selection from 'selection-popover'
import TextLength from 'toolComponents/text-length'
import { Button } from 'ui'

import 'katex/dist/katex.min.css'
import Markdown from 'toolComponents/chat/ChatMessage/Markdown'

const toolNameToContextName = {
  'writer-writeText': 'writer',
  'writer-reply': 'writer-reply',
  'writer-modifyText': 'writer-modify',
  'writer-checkText': 'writer-check',
  advisor: 'advisor',
  greatIdeas: 'ideas',
}

const WriterResult = ({
  generatedText,
  regenerate,
  folded,
  loading,
  handleSaveGeneratedTextHistory,
  toolName,
  handleStopRequest,
  selectedLanguage,
  showLength = true,
  topic,
  setGeneratedText,
}) => {
  const { height, width } = useWindowDimensions()
  const context = useContext(ViewContext)
  const [selectedText, setSelectedText] = useState('')
  const [stateHistory, setStateHistory] = useState([])
  const [selectedRange, setSelectedRange] = useState()
  const [markdownKey, setMarkdownKey] = useState(new Date())

  const parentRef = useRef(null)
  const scrollHandle = useRef(null)
  const actionsRef = useRef(null)
  const wordSelectRef = useRef() // double and triple click timeout tracking
  const clickCountRef = useRef(0) // double and triple click count tracking
  const contentRef = useRef() // Holds the value of output before keypress (used for ctrl+z)

  const changeWritingDirection = detectRtlDirection(selectedLanguage)
  let numWords = parentRef?.current && parentRef.current.textContent.split(/\s+/).length
  if (parentRef?.current && parentRef.current.textContent.length === 0) numWords = 0

  // useEffect(() => {
  //   if (!generatedText) return // Prevent scrolling to empty textbox on mobile
  //   scrollHandle?.current?.scrollIntoView({ behavior: 'smooth' })
  // }, [generatedText])

  const handleSelectionChange = () => {
    const selection = window.getSelection()
    if (selection && selection.toString()) {
      // Text is selected within the container
      setSelectedText(selection.toString())
      setSelectedRange(selection.getRangeAt(0))
    }
  }

  const handleDeleteSelectedText = () => {
    const contentEditable = parentRef.current

    // Check if the selection is within the contentEditable div
    if (!contentEditable.contains(selectedRange.startContainer)) {
      parentRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          code: 'Escape',
          bubbles: true,
          cancelable: true,
        })
      )
      return console.log('Range not found')
    }
    handleUpdateHistory(getContentToSave())

    const startContainer = selectedRange.startContainer
    const startOffset = selectedRange.startOffset
    const textBeforeSelection = startContainer.textContent.slice(0, startOffset)
    const selectedContentRange = selectedRange.cloneRange()

    // Remove a space if its present before content
    if (textBeforeSelection.slice(-1) === ' ') {
      selectedContentRange.setStart(selectedContentRange.startContainer, startOffset - 1)
    }

    // Extract the selected content
    selectedContentRange.deleteContents()

    // Collapse the range to the end to keep the cursor at the correct position
    selectedContentRange.collapse(false)

    // Update the selection with the collapsed range
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(selectedContentRange)
    handleSaveGeneratedTextHistory(getContentToSave())
    // refreshOutputStructure()
  }

  const handleChangeSelectedText = (replacementText) => {
    if (!replacementText) return
    const contentEditable = parentRef.current

    // Check if the selection is within the contentEditable div
    if (!contentEditable.contains(selectedRange.startContainer)) {
      parentRef.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          code: 'Escape',
          bubbles: true,
          cancelable: true,
        })
      )
      return console.log('Range not found')
    }
    handleUpdateHistory(getContentToSave())

    const range = selectedRange

    // Add a space after if selection has it and the replacement text doesn't
    let spaceAfter = false
    let spaceBefore = false
    if (
      range.toString()[range.toString().length - 1] === ' ' &&
      replacementText[replacementText.length - 1] !== ' '
    )
      spaceAfter = true
    if (range.toString()[0] === ' ' && replacementText[0] !== ' ') spaceBefore = true

    const newNode = document.createTextNode(
      (spaceBefore ? ' ' : '') + replacementText + (spaceAfter ? ' ' : '')
    )

    // Replace the selected content with the new text
    range.deleteContents()
    range.insertNode(newNode)

    // Move the cursor to the end of the inserted text
    range.setStartAfter(newNode)
    range.collapse(true)

    // Update the selection
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

    handleSaveGeneratedTextHistory(getContentToSave())
  }

  const getTextContext = () => {
    if (!selectedText) return
    const contentEditable = parentRef.current

    const range = document.createRange()

    range.selectNodeContents(contentEditable)
    range.setStart(selectedRange.startContainer, selectedRange.startOffset)
    range.setEnd(selectedRange.endContainer, selectedRange.endOffset)

    const textBeforeRange = range.cloneRange()
    textBeforeRange.selectNodeContents(contentEditable)
    textBeforeRange.setEnd(range.startContainer, range.startOffset)

    const textAfterRange = range.cloneRange()
    textAfterRange.selectNodeContents(contentEditable)
    textAfterRange.setStart(range.endContainer, range.endOffset)

    let spaceAfter = false
    let selectedTextValue = range.toString()
    if (selectedTextValue[selectedTextValue.length - 1] === ' ') {
      spaceAfter = true
      selectedTextValue = selectedTextValue.trimEnd()
    }

    const textBeforeSelection = textBeforeRange.toString()
    const textAfterSelection = textAfterRange.toString()
    const textContext =
      textBeforeSelection +
      "'''" +
      selectedTextValue +
      (spaceAfter ? "''' " : "'''") +
      textAfterSelection

    return textContext
  }

  const showShareModal = (textType) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(
                textType === 'full-text' ? parentRef?.current?.innerText : selectedText
              )}
              url={window.location.origin}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  const handleSelectWord = useCallback((e) => {
    if (e.target.tagName !== 'SPAN' || e.target.innerText.split(/(\s+|\n)/).length > 1) return
    const range = document.createRange()
    clickCountRef.current += 1

    if (clickCountRef.current === 2) {
      // Handle double click
      clearTimeout(wordSelectRef.current)
      wordSelectRef.current = setTimeout(() => {
        clickCountRef.current = 0
      }, 300)
      return
    } else if (clickCountRef.current === 3) {
      // Handle triple click
      clearTimeout(wordSelectRef.current)
      clickCountRef.current = 0
      return
    }

    // Handle single click
    let savedRange
    const selection = window.getSelection()
    if (selection.getRangeAt && selection.rangeCount) savedRange = selection.getRangeAt(0)

    selection.removeAllRanges()
    range.selectNodeContents(e.target)
    selection.addRange(range)
    handleSelectionChange()

    // Add a delay to position the popup properly
    wordSelectRef.current = setTimeout(() => {
      selection.removeAllRanges()
      selection.addRange(savedRange)
      wordSelectRef.current = null
      clickCountRef.current = 0
    }, 300)
  }, [])

  const handleUndo = () => {
    if (!parentRef.current || !stateHistory?.length) return

    const toSave = stateHistory[stateHistory.length - 1]
    setStateHistory((prev) => {
      prev.pop()
      return prev
    })

    handleSaveGeneratedTextHistory(toSave)
    setGeneratedText(toSave)
    setMarkdownKey(new Date())
  }

  const handleUpdateHistory = (newState) => {
    setStateHistory((prev) => {
      if (prev.length < 10) return [...prev, newState]
      return [...prev.slice(-9), newState]
    })
  }

  const getContentToSave = () => {
    return parentRef.current?.innerHTML?.slice(27, -17) // innerHTML without the markdown wrapper
  }

  return (
    <div className='flex h-full w-full flex-col justify-between px-[16px] pt-[16px]'>
      <Selection.Root>
        <Selection.Trigger className='relative -ml-4 -mt-4 pl-4 pt-4'>
          {showLength && (
            <TextLength numWords={numWords} characters={parentRef.current?.textContent?.length} />
          )}
          <div
            id='writer-output'
            contentEditable='plaintext-only'
            suppressContentEditableWarning={true}
            spellCheck={false}
            onMouseUp={() => handleSelectionChange()}
            ref={parentRef}
            dir={changeWritingDirection ? 'rtl' : 'ltr'}
            onInput={() => {
              handleSaveGeneratedTextHistory(getContentToSave())
              handleUpdateHistory(contentRef.current)
            }}
            onKeyDown={(e) => {
              contentRef.current = getContentToSave()

              if (e.key !== 'Escape') {
                // Dispatch 'Escape' to close context menu
                parentRef.current?.dispatchEvent(
                  new KeyboardEvent('keydown', {
                    key: 'Escape',
                    code: 'Escape',
                    bubbles: true,
                    cancelable: true,
                  })
                )
              }

              if (e.ctrlKey && e.key === 'z') {
                e.preventDefault()
                handleUndo()
              }
            }}
            onClick={handleSelectWord}
            className={classNames(
              'border-brand-gray-light markdown-container overflow-y-auto rounded-[10px] border p-[10px] pb-8 outline-none',
              {
                'rtl-text': changeWritingDirection,
              }
            )}
            style={{
              height:
                width >= 1024
                  ? `${height - actionsRef.current?.clientHeight - 110}px`
                  : folded
                    ? `${height}px`
                    : '600px',
            }}
            data-tooltip-id={`${toolName}-responseArea`}
            data-tooltip-place={width >= 1024 ? 'left' : 'top'}
          >
            <Markdown content={generatedText} key={markdownKey} isSplitWords />
            <div ref={scrollHandle}></div>
          </div>
        </Selection.Trigger>
        <Selection.Portal>
          <Selection.Content
            side='bottom'
            align='start'
            collisionPadding={{ bottom: 50 }}
            className='z-[9999]'
          >
            <ContextMenu
              selectedText={selectedText}
              handleChangeSelectedText={handleChangeSelectedText}
              deleteSelection={handleDeleteSelectedText}
              getTextContext={getTextContext}
            />
          </Selection.Content>
        </Selection.Portal>
      </Selection.Root>
      <div
        className={classNames('writer-result-actions py-[16px]', {
          'w-full': folded,
        })}
      >
        <div className='relative flex items-center justify-between'>
          {/* <div className='absolute -top-[8px]'>
            <EleoMascot />
          </div> */}
          <div
            className='actions relative flex w-full flex-wrap items-center justify-end gap-[10px]'
            ref={actionsRef}
          >
            {/* Copy button */}
            <Button
              color='gray'
              className='h-[45px] px-[18px]'
              data-tooltip-id={`${toolName}-copy`}
              onClick={() =>
                CopyToClipBoard(
                  parentRef?.current?.innerHTML,
                  <T keyName='eleo-text-copied-success-message'>
                    Text successfully copied on your clipboard
                  </T>,
                  true
                )
              }
            >
              <ContentCopy weight='light' style={{ color: '#363636', fontSize: 24 }} />
            </Button>

            {/* Share button */}
            <Button
              color='gray'
              className='h-[45px] px-[18px]'
              onClick={() => showShareModal('full-text')}
              data-tooltip-id={`${toolName}-share`}
            >
              <Share weight='fill' style={{ color: '#363636', fontSize: 24 }} />
            </Button>

            {/* Continue in chat button */}
            {Boolean(parentRef.current?.textContent?.trim().length && topic?.trim().length) &&
              !loading.status && (
                <Link
                  id='continue-in-chat'
                  className='flex w-full items-center md:w-auto'
                  to={'/chat'}
                  state={{
                    history: [
                      { role: 'user', content: topic },
                      { role: 'assistant', content: parentRef.current?.textContent },
                    ],
                    contextKey: toolNameToContextName[toolName],
                  }}
                >
                  <Button color='gray' className='h-[45px] w-full px-[18px]'>
                    <div className='flex items-center gap-[6px]'>
                      <p className='text-[15px] font-medium uppercase leading-none'>
                        <T keyName='eleo-continue-in-chat'>continue in chat</T>
                      </p>
                    </div>
                  </Button>
                </Link>
              )}

            {/* Regenerate / Stop generating button */}
            {loading.status ? (
              <Button
                color='gray'
                className='h-[45px] w-full px-[18px] md:w-auto'
                onClick={() => handleStopRequest()}
              >
                <div className='flex items-center gap-[6px]'>
                  <p className='text-[15px] font-medium uppercase leading-none'>
                    <T keyName='eleo-result-stop-generating'>stop generating</T>
                  </p>
                  <Refresh
                    weight='light'
                    style={{ color: '#363636', fontSize: 24 }}
                    className={classNames({
                      'animate-spin': loading.name,
                    })}
                  />
                </div>
              </Button>
            ) : (
              <Button
                color='gray'
                className='h-[45px] w-full px-[18px] md:w-auto'
                onClick={() => regenerate()}
                data-tooltip-id={`${toolName}-regenerate`}
              >
                <div className='flex items-center gap-[6px]'>
                  <p className='text-[15px] font-medium uppercase leading-none'>
                    <T keyName='eleo-result-regenerate'>regenerate</T>
                  </p>
                  <Refresh weight='light' style={{ color: '#363636', fontSize: 24 }} />
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WriterResult
