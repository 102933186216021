import { Chat } from 'views/tools/chat'
import { Translator } from 'views/tools/translator'
import { Image } from 'views/tools/image'
import { ImageModify } from 'views/tools/imageModify'
import { Writer } from 'views/tools/writer'
import { WriterModifyText } from 'views/tools/writer-modify-text'
import { WriterCheckText } from 'views/tools/writer-check-text'
import { Gallery } from 'views/tools/gallery'
import { GreatIdeas } from 'views/tools/great-ideas'
import { Advisor } from 'views/tools/advisor'
import { Reply } from 'views/tools/reply'
import { ChatbotLibrary } from 'views/tools/chatbot-library'
import { ChatbotLibraryCategory } from 'views/tools/chatbot-library-category'
import { ChatbotLibraryRecent } from 'views/tools/chatbot-library-recent'
import { ChatbotLibraryLiked } from 'views/tools/chatbot-library-liked'
import { ChatbotLibraryPopular } from 'views/tools/chatbot-library-popular'
import ChatbotLibraryChatbot from 'views/tools/chatbot-library-chatbot'
import { ChatbotLibraryNew } from 'views/tools/chatbot-library-new'
import ChatbotLibraryAuthor from 'views/tools/chatbot-library-author'

const Routes = [
  {
    path: '/chat',
    view: Chat,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-chat',
    tokens: 'words',
  },
  {
    path: '/translator',
    view: Translator,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-translator',
    tokens: 'words',
  },
  {
    path: '/image',
    view: Image,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-image',
    tokens: 'images',
  },
  {
    path: '/image/modify',
    view: ImageModify,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-image',
    tokens: 'images',
  },
  {
    path: '/image/gallery',
    view: Gallery,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-gallery',
    tokens: 'images',
  },
  {
    path: '/writer',
    view: Writer,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-writer',
    tokens: 'words',
  },
  {
    path: '/writer/reply',
    view: Reply,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-writer-reply',
    tokens: 'words',
  },
  {
    path: '/writer/modify',
    view: WriterModifyText,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-writer-modify',
    tokens: 'words',
  },
  {
    path: '/writer/check',
    view: WriterCheckText,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-writer-check',
    tokens: 'words',
  },
  {
    path: '/great-ideas',
    view: GreatIdeas,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-ideas',
    tokens: 'words',
  },
  {
    path: '/advisor',
    view: Advisor,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-advisor',
    tokens: 'words',
  },
  {
    path: '/chatbot/chat/:botName',
    view: ChatbotLibraryChatbot,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-chatbot',
    tokens: 'words',
  },
  {
    path: '/chatbot/library',
    view: ChatbotLibrary,
    layout: 'app',
    permission: 'user',
    title: 'eleo-title-chatbot-library',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/category/:category',
    view: ChatbotLibraryCategory,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library category',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/recent',
    view: ChatbotLibraryRecent,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library - recently used',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/new',
    view: ChatbotLibraryNew,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library - recently added',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/liked',
    view: ChatbotLibraryLiked,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library - your liked',
  },
  {
    path: '/chatbot/library/popular',
    view: ChatbotLibraryPopular,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library - recently added',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/profile/:author',
    view: ChatbotLibraryAuthor,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library',
    tokens: 'words',
  },
  {
    path: '/chatbot/library/profile',
    view: ChatbotLibraryAuthor,
    layout: 'app',
    permission: 'user',
    title: 'Chatbot library',
    tokens: 'words',
  },
]

export default Routes
