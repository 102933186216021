import { T, useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'
import {
  Favorite,
  FavoriteBorder,
  Info,
  QuestionAnswer,
  Share,
  ThumbUp,
  ThumbUpOffAltOutlined,
} from '@mui/icons-material'
import { CopyToClipBoard } from 'helpers/clipboard'
import { Microbutton } from 'ui'

export default function LibraryItem({ item, handleLikeChatbot, handleRecommendChatbot }) {
  const navigate = useNavigate()
  const { t } = useTranslate()

  return (
    <div className='relative flex h-[200px] w-[190px] min-w-[200px] flex-col justify-between rounded-[10px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] transition hover:scale-[1.03] md:h-[230px] md:w-[210px] md:rounded-[20px] xl:h-[350px] xl:w-[300px]'>
      <div className='flex max-h-full flex-1 flex-col items-center overflow-hidden p-4 md:w-auto md:p-5 md:text-center xl:p-[30px] xl:pb-14'>
        <div className='mb-2 flex items-start gap-[6px] text-[15px] font-semibold leading-[1.2em] md:block md:text-[16px] xl:text-[18px]'>
          {item.avatar && (
            <img
              src={item.avatar}
              alt={item.displayName ?? item.name}
              className='mx-auto mb-2 size-[80px] rounded-full'
            />
          )}
          <h2 className='text-[20px]'>{item.displayName?.length ? item.displayName : item.name}</h2>
          <div
            onClick={(e) => {
              e.stopPropagation()
              navigate(`/chatbot/library/profile/${item.author}`)
            }}
            className='cursor-pointer text-balance text-[12px] font-semibold'
          >
            <T
              keyName='eleo-chatbot-author'
              defaultValue='Author: <span>{author}</span>'
              params={{ author: item.author, span: <span className='text-brand-violet'></span> }}
            />
          </div>

          <div
            onClick={(e) => e.stopPropagation()}
            className='right-4 top-4 -m-2 hidden cursor-pointer items-center gap-1 p-2 md:absolute md:flex'
          >
            <Share
              fontSize='small'
              className='text-brand-violet opacity-20 '
              onClick={() => CopyToClipBoard(`${window.location.origin}/chatbot/chat/${item.name}`)}
            />
            <QuestionAnswer fontSize='small' className='text-brand-violet opacity-20' />
            {item.longDescription && (
              <div data-tooltip-id={`eleo-tooltip`} data-tooltip-keyname={item.longDescription}>
                <Info fontSize='small' className='text-brand-violet opacity-20 ' />
              </div>
            )}
          </div>
        </div>
        <div className='flex-1 text-balance text-[12px] leading-[1.5em] md:text-[14px] xl:text-[15px]'>
          {item.shortDescription}
        </div>

        {item.category && (
          <span className='text-brand-violet absolute left-4 top-3 inline-flex max-w-[75px] flex-shrink-0 text-start text-xs font-medium'>
            {t(item.category)}
          </span>
        )}
        <Microbutton
          type='main'
          text={<T keyName='eleo-chatbot-start-talking'>Start talking</T>}
          onClick={() => navigate(`/chatbot/chat/${item.name}`)}
        />
      </div>
      <div className='absolute bottom-0 flex h-10 w-full cursor-pointer rounded-b-[10px] border-t bg-white'>
        <div
          onClick={() => handleRecommendChatbot(item.id)}
          className='text-brand-violet flex flex-1 items-center justify-center gap-1 border-r'
        >
          {item.isRecommended ? (
            <ThumbUp fontSize='inherit' />
          ) : (
            <ThumbUpOffAltOutlined fontSize='inherit' />
          )}
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-recommend', 'Recommend')}
          </span>
        </div>
        <div
          onClick={() => handleLikeChatbot(item.id)}
          className='text-brand-violet flex flex-1 items-center justify-center gap-1'
        >
          {item.isLiked ? <Favorite fontSize='inherit' /> : <FavoriteBorder fontSize='inherit' />}
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-like', 'Like it')}
          </span>
        </div>
      </div>
    </div>
  )
}
