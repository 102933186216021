import { ToolLayoutPrimary } from 'components/lib'
import axios from 'axios'
import { errorToast } from 'helpers'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  generateChatBot,
  getChatBotList,
  SelectChatBotInputValues,
  SelectChatBotSettings,
  setClearFields,
} from 'store/account/chatBot'
import ActionModal from 'toolComponents/writer/ActionModal'
import { T } from '@tolgee/react'
import ChatGenerator from './chatGenerator'
import ChatResult from './chatResult'
import { ChatBotAPIService } from 'service/chat-bot-rest-api.service'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useYourStory } from 'components/hooks/yourStory'
import Tour from 'components/tour'
import { useDocumentContext } from 'components/hooks/context'
import { ContextModal } from 'components/document-context/ContextModal'

// Tour steps
const steps = [
  {
    target: '#selector',
    content: (
      <T keyName='eleo-tour-chatbot-1'>
        Update your Chatbot. If you have previously created a chatbot, you can choose it here for
        editing.
      </T>
    ),
  },
  {
    target: '#name',
    content: (
      <T keyName='eleo-tour-chatbot-2'>
        Choose a name for your Chatbot. This name will also appear in the chatbot link.
      </T>
    ),
  },
  {
    target: '#story',
    content: (
      <T keyName='eleo-tour-chatbot-3'>
        Add Your Story. Your Chatbot will draw information about you, your company, or how to behave
        from the Your Story indicated here.
      </T>
    ),
  },
  {
    target: '#advanced',
    content: (
      <T keyName='eleo-tour-chatbot-4'>
        Select additional options. Set the tone, language, introduction, avatar, and Chatbot
        response length.
      </T>
    ),
  },
  {
    target: '#submit',
    content: (
      <T keyName='eleo-tour-chatbot-5'>
        Create or update the Chatbot. You can have multiple different Chatbots running.
      </T>
    ),
  },
  {
    target: '#output',
    content: (
      <T keyName='eleo-tour-chatbot-6'>
        Manage your Chatbot. Copy the chatbot link, place the Chatbot on your website or on
        Telegram.
      </T>
    ),
  },
]

const ChatBot = () => {
  const [folded, setFolded] = useState(false)
  const [uplodLoading, setUploadLoading] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(false)
  const [chatIdToDelete, setChatIdToDelete] = useState('')
  const dispatch = useDispatch()
  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const chatBotSettings = useSelector(SelectChatBotSettings)
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const documentContext = useDocumentContext('chatbot-creator')

  const [isTourOverride, setIsTourOverride] = useState(false)

  const handleDeleteChatBot = async (chatId) => {
    setChatIdToDelete(chatId)
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  const { formatted: stories } = useYourStory()

  const handleGenerateChatBot = async () => {
    if (!chatBotInputValues.name.length) {
      errorToast(<T keyName='eleo-chat-bot-name-required'>Chat Bot Name Is Required!</T>)
      return
    }
    // if (chatBotInputValues.name.length < 5) {
    //   errorToast(
    //     <T keyName='eleo-chat-bot-name-length-error'>
    //       Chat Bot Name Should Contain At Least 5 Characters!
    //     </T>
    //   )
    //   return
    // }

    if (!chatBotInputValues.monthlyLimit.length) {
      errorToast(
        <T keyName='eleo-chat-bot-name-monthly-limit-error'>Please Point Monthly Limit!</T>
      )
      return
    }

    dispatch(
      generateChatBot({
        ...chatBotInputValues,
        settings: chatBotSettings,
        stories: documentContext.docContext,
      })
    )
  }

  async function handleFileUpload(e, handler, dir) {
    try {
      setUploadLoading(true)

      const url = '/api/utility/upload'
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('dir', dir ? dir : 'chatBot/')

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }

      const res = await axios.post(url, data, config)
      handler(res.data.items[0])
    } catch (err) {
      console.log(err)
    } finally {
      setUploadLoading(false)
    }
  }

  useEffect(() => {
    dispatch(getChatBotList())
  }, [dispatch])

  function handleTourProgress(e) {
    if (e.action === 'reset' || e.action === 'close' || e.action === 'stop') {
      setIsTourOverride(false)
      return
    }
    if (!e.action === 'update') return

    const step = e.index
    if (step === 0) setIsTourOverride(true)
  }

  return (
    <>
      <div className='flex h-full flex-col'>
        <ToolLayoutPrimary
          folded={folded}
          setFolded={setFolded}
          storageKey='chatbot'
          maxPanelWidth={600}
          leftSideContent={
            <ChatGenerator
              isShowAdvanced={isTourOverride || isShowAdvanced}
              setIsShowAdvanced={setIsShowAdvanced}
              storyTemplateOptions={stories}
              handleGenerateChatBot={handleGenerateChatBot}
              handleDeleteChatBot={handleDeleteChatBot}
              handleFileUpload={handleFileUpload}
              documentContext={documentContext}
            />
          }
          rightSideContent={
            <ChatResult
              handleGenerateChatBot={handleGenerateChatBot}
              hidden={!isTourOverride && !chatBotInputValues.selectedChatBot}
              handleFileUpload={handleFileUpload}
            />
          }
        />
        {modalIsVisible.visible && (
          <ActionModal
            modalKey={modalIsVisible.content}
            setModalIsVisible={setModalIsVisible}
            reloadTemplatesData={() => {
              dispatch(getChatBotList())
              if (chatIdToDelete === chatBotInputValues.selectedChatBot) {
                dispatch(setClearFields())
              }
            }}
            modalHeaderText={<T keyName='eleo-delete-bot-modal-title'>Delete Chat Bot</T>}
            deleteTemplateEndpoint={() =>
              ChatBotAPIService.deleteChatBot({ chatBotId: chatIdToDelete })
            }
            mainTitle={
              <T keyName='eleo-delete-bot-header'>Are you sure you want to delete this Chat Bot?</T>
            }
          />
        )}
        <HelpTooltips tool='chatBotPanel' />
        <Tour steps={steps} name='chatbot' callback={handleTourProgress} />
      </div>
      <ContextModal documentContext={documentContext} docOptions={['stories']} />
    </>
  )
}

export default ChatBot
