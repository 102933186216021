import { ChevronLeft, ChevronRight, VolumeUp } from '@mui/icons-material'
import { T, useTolgee, useTranslate } from '@tolgee/react'
import { AuthContext } from 'app/auth'
import axios from 'axios'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { useVoices } from 'components/hooks/voices'
import { useWindowDimensions } from 'components/hooks/window'
import { useAPI } from 'components/lib'
import { errorToast, transformBotName, transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectChatBotInputValues,
  SelectChatBotListData,
  SelectChatBotListOptions,
  setChatBotInputValues,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import ImageDropZone from 'toolComponents/image/ImageDropZone'
import MoreOptions from 'toolComponents/more-options'
import { ToneInputData } from 'toolComponents/writer/writer-options-data'
import { Input, Microbutton, ModelSelector, ReactSelect, Select, Toggle } from 'ui'

const introValueByLang = {
  'fr-FR': 'Bonjour, comment puis-je vous aider?',
  pl: 'Dzień dobry, jak mogę pomóc?',
  es: 'Hola, ¿cómo puedo ayudarle?',
  en: 'Hello, How can I help you?',
}

export default function ChatOptions({
  isShowAdvanced,
  setIsShowAdvanced,
  storyTemplateOptions,
  handleDeleteChatBot,
  handleFileUpload,
  documentContext,
}) {
  const { height } = useWindowDimensions()
  const authContext = useContext(AuthContext)
  const tolgee = useTolgee()
  const pendingLang = tolgee.getPendingLanguage()
  const { t } = useTranslate()
  const isPanelLarge = usePanelSize('chatbot')
  const [audioStream, setAudioStream] = useState()
  const authors = useAPI('/api/author/list')

  const dispatch = useDispatch()
  const chatBotSettings = useSelector(SelectChatBotInputValues)
  const chatBotListOptions = useSelector(SelectChatBotListOptions)

  const chatBotList = useSelector(SelectChatBotListData)
  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const [baseImage, setBaseImage] = useState(chatBotSettings.avatar)

  const { chatbotCategories } = useChatbotCategories()

  const voices = useVoices()

  const handleTTS = async () => {
    const data = {
      input: chatBotSettings.intro,
      voiceId: chatBotSettings.voice,
    }

    const res = await fetch(axios.defaults.baseURL + '/api/utility/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  const handleDispatchFieldValue = (params) => {
    dispatch(setChatBotInputValues(params))
  }

  const handleSetAvatar = (image) => {
    handleDispatchFieldValue({ field: 'avatar', value: image })
  }

  const handleSelectBot = (data) => {
    const selectedChatBot = chatBotList.find((bot) => bot.id === data.value)

    dispatch(
      setSelectedChatBotValues({
        avatar: selectedChatBot.avatar,
        language: selectedChatBot.language,
        intro: selectedChatBot.intro,
        monthlyLimit: selectedChatBot.monthlyLimit,
        name: selectedChatBot.name,
        responseLength: selectedChatBot.responseLength,
        storyId: selectedChatBot.storyId,
        tone: selectedChatBot.tone,
        wordsConsumed: selectedChatBot.wordsConsumed,
        category: selectedChatBot.category,
        voice: selectedChatBot.voice,
        isPublic: selectedChatBot.isPublic,
        modelId: selectedChatBot.modelId,
        displayName: selectedChatBot.displayName,
        shortDescription: selectedChatBot.shortDescription,
        longDescription: selectedChatBot.longDescription,
        allowWebSearch: selectedChatBot.allowWebSearch,
        allowContext: selectedChatBot.allowContext,
        author: selectedChatBot.author,
        allowLanguageChange: selectedChatBot.allowLanguageChange,
        isStartWithAudio: selectedChatBot.isStartWithAudio,
        instructions: selectedChatBot.instructions ?? '',
      })
    )

    dispatch(
      setSelectedChatBotSettings({
        ...selectedChatBot.settings,
      })
    )

    documentContext.clearContext()
    selectedChatBot.stories?.map((story) =>
      documentContext.addDocument({
        label: story.name,
        type: 'story',
        id: story.id,
      })
    )

    handleDispatchFieldValue({ field: 'selectedChatBot', value: data.value })
  }

  useEffect(() => {
    setBaseImage(chatBotSettings.avatar)
  }, [chatBotSettings.avatar])

  useEffect(() => {
    if (!chatBotSettings.intro.length) {
      handleDispatchFieldValue({
        field: 'intro',
        value: introValueByLang[pendingLang],
      })
    }
  }, [pendingLang])

  const renderMonthlyLimitAlert = useCallback(() => {
    const calculatedValue = Number(chatBotSettings.monthlyLimit) - chatBotSettings.wordsConsumed

    if (chatBotSettings.wordsConsumed > 0 && calculatedValue > 0) {
      return (
        <p className='bg-brand-green rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          {calculatedValue} -{' '}
          <T keyName='eleo-chat-bot-monthly-limit-exceeded-error'>
            words left until the end of the limit
          </T>
        </p>
      )
    }

    if (chatBotSettings.wordsConsumed > 0 && calculatedValue < 0) {
      return (
        <p className='bg-brand-pink rounded-[10px] px-[10px] py-[5px] text-[14px] leading-[1.2em] text-[#ffffff]'>
          <T
            keyName='eleo-chat-bot-monthly-limit-exceeded-error-secondary'
            params={{ calculatedValue: Math.abs(calculatedValue) }}
            defaultValue='monthly word limit exceeded by - {calculatedValue} words'
          ></T>
        </p>
      )
    }
  }, [chatBotSettings])

  return (
    <div
      className={classNames(
        'relative flex flex-col break-words  pb-6 pt-[20px]',
        isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
      )}
      style={{ gap: height <= 768 ? '14px' : '16px' }}
    >
      <div
        id='selector'
        className='flex w-full items-center gap-[10px]'
        data-tooltip-id='chatBotPanel-activeChatBot'
      >
        <FieldDescription>
          <T keyName='eleo-chat-bot-select-label'>Chat Bot</T>
        </FieldDescription>
        <ReactSelect
          options={chatBotListOptions}
          // defaultValue={options[0]}
          setSelectedValue={({ data }) => handleSelectBot(data)}
          placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
          CSSClassNames={{ singleValue: '!lowercase' }}
          value={transformSelectOptionValue(
            chatBotListOptions,
            chatBotSettings.selectedChatBot,
            null
          )}
          showDeleteIcon
          deleteAction={(chatId) => handleDeleteChatBot(chatId)}
          // name="FORMAT"
        />
      </div>
      <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
        <div
          id='name'
          className='flex !w-full items-start gap-[12px]'
          data-tooltip-id='chatBotPanel-yourChatBotName'
        >
          <FieldDescription>
            <T keyName='eleo-chat-bot-name-label'>Your Chat Bot Name</T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-chat-bot-name-label', 'Your Chat Bot Name')}
            className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={chatBotSettings.name}
            onChange={(name) =>
              handleDispatchFieldValue({ field: 'name', value: transformBotName(name) })
            }
          />
        </div>
        {/* <div
          id='story'
          className={classNames('flex w-full  items-center gap-[10px]')}
          data-tooltip-id='chatBotPanel-yourStory'
        >
          <FieldDescription>
            <T keyName='eleo-your-story'>Your story</T>
          </FieldDescription>
          <ReactSelect
            options={storyTemplateOptions}
            isClearable
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            setSelectedValue={({ data }) =>
              handleDispatchFieldValue({ field: 'storyId', value: data?.value })
            }
            value={transformSelectOptionValue(storyTemplateOptions, chatBotSettings.storyId, null)}
          />
        </div> */}
      </div>
      <div>
        <ContextField documentContext={documentContext} onlyStory />
      </div>
      <div
        id='advanced'
        className={classNames(
          'flex flex-col gap-[12px]',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            className='flex w-full items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='chatBotPanel-tone'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <ReactSelect
              options={ToneInputData}
              isClearable
              // defaultValue={options[0]}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              setSelectedValue={({ value }) => handleDispatchFieldValue({ field: 'tone', value })}
              value={transformSelectOptionValue(ToneInputData, chatBotSettings.tone, null)}
              menuPlacement='bottom'
            />
          </div>
          <div
            className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='chatBotPanel-language'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <ReactSelect
              options={languages}
              setSelectedValue={({ value }) => {
                handleDispatchFieldValue({ field: 'language', value })
              }}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(
                languages,
                chatBotSettings.language,
                defaultToolOutPutLanguage
              )}
              menuPlacement='bottom'
            />
          </div>
        </div>
        {/* Show advanced button */}
        <div className='flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
          style={{ gap: height <= 768 ? '14px' : '16px' }}
        >
          <div className='items-top flex !w-full gap-[12px]' data-tooltip-id='chatBotPanel-intro'>
            <FieldDescription>
              <T keyName='eleo-chat-bot-intro-label'>Intro</T>
            </FieldDescription>
            <Input
              isTextArea
              className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              value={chatBotSettings.intro}
              onChange={(intro) =>
                handleDispatchFieldValue({
                  field: 'intro',
                  value: intro,
                })
              }
            />
          </div>
          <div
            className='items-top flex !w-full gap-[12px]'
            data-tooltip-id='chatBotPanel-instructions'
          >
            <FieldDescription>
              <T keyName='eleo-chat-bot-instructions-label'>Chatbot instructions</T>
            </FieldDescription>
            <Input
              isTextArea
              className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              value={chatBotSettings.instructions}
              onChange={(intro) =>
                handleDispatchFieldValue({
                  field: 'instructions',
                  value: intro,
                })
              }
            />
          </div>
          <div className='flex !w-full !flex-wrap items-center gap-[12px]'>
            <div
              className='flex !w-full items-start gap-[12px]'
              data-tooltip-id='chatBotPanel-monthlyLimit'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-max-response-monthly-limit'>Monthly Limit (Max Words)</T>
              </FieldDescription>
              <Input
                placeholder='2000'
                type='number'
                className='placeholder-font-regular w-full min-w-[100px] rounded-[8px] text-[13px] placeholder-[#36363680]'
                value={chatBotSettings.monthlyLimit}
                onChange={(limit) =>
                  handleDispatchFieldValue({ field: 'monthlyLimit', value: limit })
                }
              />
              {renderMonthlyLimitAlert()}
            </div>
            <div
              className='flex w-full items-start gap-[12px] lg:w-1/2'
              data-tooltip-id='chatBotPanel-responseLength'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-max-response-length-limit'>Response Length (Max Words)</T>
              </FieldDescription>
              <Input
                placeholder='20-500'
                type='number'
                className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
                value={chatBotSettings.responseLength}
                onChange={(length) =>
                  handleDispatchFieldValue({ field: 'responseLength', value: length })
                }
              />
            </div>
          </div>

          <div className='items-top flex !w-full gap-[12px]' data-tooltip-id='chatBotPanel-avatar'>
            <FieldDescription>
              <T keyName='eleo-chat-bot-upload-image-avatar-label'>Avatar</T>
            </FieldDescription>
            <ImageDropZone
              baseImage={baseImage}
              setBaseImage={setBaseImage}
              handleFileUpload={(e) => handleFileUpload(e, handleSetAvatar)}
              classes='h-[80%] !w-full px-0 !m-0 text-center min-w-[150px]'
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={{
                ...(aiController.getTextModel(chatBotSettings.modelId) ||
                  aiController.getDefaultTextModel()),
                description: undefined,
              }}
              handleModelChange={(model) =>
                handleDispatchFieldValue({ field: 'modelId', value: model.value })
              }
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'default'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://www.techopedia.com/claude-vs-chatgpt'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-share-in-public-chatbots-library'>
                Share in public chatbots library
              </T>
            </p>
            <Toggle
              isChecked={chatBotSettings.isPublic}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'isPublic', value: isEnabled })
              }
            />
            <p className='text-brand-gray block text-[11px]'>
              <T keyName='eleo-share-in-public-chatbots-library-info'>
                The chatbot will appear in the public library after it has been approved by the Eleo
                team. This usually takes up to 24 hours.
              </T>
            </p>
          </div>

          {authContext.permission?.master && authors.data && (
            <div id='author' className='flex w-full items-start gap-[12px]'>
              <FieldDescription>
                <T keyName='eleo-chat-bot-author-label'>Author name</T>
              </FieldDescription>

              <ReactSelect
                value={{ value: chatBotSettings.author, label: chatBotSettings.author }}
                options={authors.data?.map((author) => ({
                  value: author.name,
                  label: author.name,
                }))}
                setSelectedValue={(e) =>
                  handleDispatchFieldValue({ field: 'author', value: e.value })
                }
              />
            </div>
          )}
          <div
            className={classNames('flex flex-col', chatBotSettings.isPublic ? '' : 'hidden')}
            style={{ gap: height <= 768 ? '14px' : '16px' }}
          >
            <div
              id='displayName'
              className='flex !w-full items-start gap-[12px]'
              data-tooltip-id='chatBotPanel-displayName'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-displayName-label'>Chatbot public display name</T>
              </FieldDescription>
              <Input
                placeholder={t('eleo-chat-bot-displayName-label', 'Chatbot public display name')}
                className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
                maxLength={30}
                value={chatBotSettings.displayName}
                onChange={(displayName) =>
                  handleDispatchFieldValue({ field: 'displayName', value: displayName })
                }
              />
            </div>
            <div
              className='items-top flex !w-full gap-[12px]'
              data-tooltip-id='chatBotPanel-shortDescription'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-short-description-label'>Short description</T>
              </FieldDescription>
              <Input
                className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
                maxLength={40}
                isTextArea
                value={chatBotSettings.shortDescription}
                onChange={(shortDescription) =>
                  handleDispatchFieldValue({
                    field: 'shortDescription',
                    value: shortDescription,
                  })
                }
              />
            </div>
            <div
              className='items-top flex !w-full gap-[12px]'
              data-tooltip-id='chatBotPanel-longDescription'
            >
              <FieldDescription>
                <T keyName='eleo-chat-bot-long-description-label'>Long description</T>
              </FieldDescription>
              <Input
                className='placeholder-font-regular min-h-[60px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
                maxLength={400}
                isTextArea
                value={chatBotSettings.longDescription}
                onChange={(longDescription) =>
                  handleDispatchFieldValue({
                    field: 'longDescription',
                    value: longDescription,
                  })
                }
              />
            </div>
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-chatbots-library-allow-language-change'>Allow language change</T>
            </p>
            <Toggle
              isChecked={chatBotSettings.allowLanguageChange}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'allowLanguageChange', value: isEnabled })
              }
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-chatbots-library-allow-web-search'>Allow web searching</T>
            </p>
            <Toggle
              isChecked={chatBotSettings.allowWebSearch}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'allowWebSearch', value: isEnabled })
              }
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-chatbots-library-allow-context'>Allow users to include context</T>
            </p>
            <Toggle
              isChecked={chatBotSettings.allowContext}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'allowContext', value: isEnabled })
              }
            />
          </div>
          <div className='flex !w-full items-center justify-between gap-[10px]'>
            <p className='text-brand-body flex items-center gap-[3px] text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-chatbots-library-start-with-audio'>Say the intro</T>
            </p>
            <Toggle
              isChecked={chatBotSettings.isStartWithAudio}
              setIsChecked={(isEnabled) =>
                handleDispatchFieldValue({ field: 'isStartWithAudio', value: isEnabled })
              }
            />
          </div>
          <div
            className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='chatBotPanel-category'
          >
            <FieldDescription>
              <T keyName='eleo-chatbot-category'>Category</T>
            </FieldDescription>
            <ReactSelect
              options={chatbotCategories}
              setSelectedValue={({ value }) =>
                handleDispatchFieldValue({ field: 'category', value })
              }
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(
                chatbotCategories,
                chatBotSettings.category,
                undefined
              )}
              menuPlacement='top'
            />
          </div>
          <div
            className='flex !w-full items-center gap-[10px]'
            data-tooltip-id='chatBotPanel-voice'
          >
            <FieldDescription>
              <T keyName='eleo-chatbot-voice'>Voice</T>
            </FieldDescription>
            <ReactSelect
              options={voices.data?.voices}
              setSelectedValue={({ value }) => handleDispatchFieldValue({ field: 'voice', value })}
              placeHolder={t('eleo-chat-settings-model-default', 'Default')}
              value={transformSelectOptionValue(
                voices.data?.voices,
                chatBotSettings.voice,
                undefined
              )}
              menuPlacement='top'
              isClearable
            />
            <Microbutton
              className='max-w-16'
              iconPlacement='left'
              variant={'solid'}
              icon={<VolumeUp fontSize='inherit' />}
              disabled={false}
              onClick={handleTTS}
            />
            <AudioPlayer streamSource={audioStream} setSource={setAudioStream} />
          </div>
        </div>
      </div>
    </div>
  )
}
