import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { errorToast, successToast } from 'helpers'
import { ChatBotAPIService } from 'service/chat-bot-rest-api.service'
import { T } from '@tolgee/react'

export const chatBotInitialState = {
  inputValues: {
    name: '',
    storyId: null,
    selectedChatBot: null,
    tone: '',
    language: 'auto',
    intro: '',
    avatar: '',
    responseLength: '',
    monthlyLimit: '15000',
    isGuruEnabled: false,
    wordsConsumed: 0,
    author: '',
    allowLanguageChange: false,
    allowWebSearch: false,
    allowContext: false,
    isStartWithAudio: false,
    instructions: '',
    shortDescription: '',
    longDescription: '',
    displayName: '',
    isPublic: false,
    category: 'eleo-chatbot-category-other',
  },
  settings: {
    activeOnEleo: false,
    websites: [],
    telegramToken: '',
    telegramBotActive: false,
    activeOnRemoteWebsite: false,
    chatPlacement: 'bottom-right',
    windowWidth: '700',
    windowHeight: '500',
    chatIcon: '',
  },
  chatBotData: [],
  chatBotOptionData: [],
  states: {
    generateChat: {
      loading: false,
      error: null,
    },
    getChatBotList: {
      loading: false,
      error: null,
    },
  },
}

export const generateChatBot = createAsyncThunk('chatBot/generate', async (data, { dispatch }) => {
  try {
    const response = await ChatBotAPIService.generateChatBot(data)
    const responseJSON = await response.json()
    if (responseJSON.success) {
      successToast(<T keyName={`eleo-success-${responseJSON.message}`}>{responseJSON.message}</T>)
      dispatch(getChatBotList())

      if (responseJSON.id) {
        dispatch(setChatBotInputValues({ field: 'selectedChatBot', value: responseJSON.id }))
      }

      return response.data
    } else {
      errorToast(<T keyName={`eleo-error-${responseJSON.error}`}>{responseJSON.error}</T>)
    }
  } catch (error) {
    errorToast(error.message)
  }
})

export const getChatBotList = createAsyncThunk('chatBot/getList', async () => {
  const response = await ChatBotAPIService.getChatBotList()
  const data = await response.json()
  return data
})

export const chatBotSlice = createSlice({
  name: 'chatBot',
  initialState: chatBotInitialState,
  reducers: {
    setChatBotInputValues: (state, action) => {
      const { field, value } = action.payload
      state.inputValues[field] = value
    },
    setChatBotSettings: (state, action) => {
      const { field, value } = action.payload
      state.settings[field] = value
    },
    setSelectedChatBotValues: (state, action) => {
      state.inputValues = action.payload
    },
    setSelectedChatBotSettings: (state, action) => {
      state.settings = action.payload
    },
    setClearFields: (state) => {
      state.inputValues = chatBotInitialState.inputValues
      state.settings = chatBotInitialState.settings
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateChatBot.pending, (state) => {
        state.states.generateChat.loading = true
        state.states.generateChat.error = null
      })
      .addCase(generateChatBot.fulfilled, (state, action) => {
        state.states.generateChat.loading = false
        state.states.generateChat.error = null
      })
      .addCase(generateChatBot.rejected, (state, action) => {
        state.states.generateChat.loading = false
        state.states.generateChat.error = 'Failed to create bot'
      })

      .addCase(getChatBotList.pending, (state) => {
        state.states.getChatBotList.loading = true
        state.states.getChatBotList.error = null
      })
      .addCase(getChatBotList.fulfilled, (state, action) => {
        state.states.getChatBotList.loading = false
        state.states.getChatBotList.error = null

        state.chatBotOptionData = action.payload.map((bot) => {
          return {
            value: bot.id,
            label: bot.name,
          }
        })

        state.chatBotData = action.payload
      })
      .addCase(getChatBotList.rejected, (state, action) => {
        state.states.getChatBotList.loading = false
        state.states.getChatBotList.error = 'Failed fetch chat bots'
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setChatBotInputValues,
  setSelectedChatBotValues,
  setClearFields,
  setChatBotSettings,
  setSelectedChatBotSettings,
} = chatBotSlice.actions

//selectors
export const SelectChatBotInputValues = (state) => state.chatBot.inputValues
export const SelectGenerateChatAsyncStates = (state) => state.chatBot.states.generateChat
export const SelectChatBotListOptions = (state) => state.chatBot.chatBotOptionData
export const SelectChatBotListData = (state) => state.chatBot.chatBotData
export const SelectChatBotSettings = (state) => state.chatBot.settings

export default chatBotSlice.reducer
