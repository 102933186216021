import React, { useCallback, useEffect, useRef, useState } from 'react'
import ImageDropZone from 'toolComponents/image/ImageDropZone'
import ChatPositionSelector from './chatPositionSelector'
import ChatStatusSwitcher from './chatStatusSwitcher'
import { Input, Button, MultipleSelect } from 'ui'
import { T, useTranslate } from '@tolgee/react'
import { useDispatch } from 'react-redux'
import {
  SelectChatBotSettings,
  setChatBotSettings,
  SelectChatBotInputValues,
  generateChatBot,
} from 'store/account/chatBot'
import { useSelector } from 'react-redux'
import { errorToast, isValidUrl } from 'helpers'
import { CopyToClipBoard } from 'helpers/clipboard'
import axios from 'axios'
const Settings = require('settings.json')

const ChatSettings = ({ handleFileUpload }) => {
  const websiteCodeRef = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const chatSettings = useSelector(SelectChatBotSettings)

  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const [value, setValue] = useState(chatSettings.websites)

  const [baseImage, setBaseImage] = useState(chatSettings.chatIcon)
  const handleDispatchChatSettings = (params) => {
    dispatch(setChatBotSettings(params))
  }

  const handleSetChatIcon = (url) => {
    dispatch(setChatBotSettings({ field: 'chatIcon', value: url }))
  }

  const handleAddWebsites = () => {
    const values = value?.map((website) => transformLinkToOrigin(website?.value || website))
    const filteredValues = values.filter((val) => val)

    dispatch(setChatBotSettings({ field: 'websites', value: filteredValues }))
  }

  const transformLinkToOrigin = (link) => {
    if (link && isValidUrl(link)) {
      const origin = new URL(link).origin
      return origin
    } else {
      return null
    }
  }

  const handleChangeChatBotStatus = (status) => {
    const data = {
      chatBotId: chatBotInputValues.selectedChatBot,
      ...chatBotInputValues,
      settings: {
        ...chatSettings,
        activeOnRemoteWebsite: status,
      },
    }

    dispatch(setChatBotSettings({ field: 'activeOnRemoteWebsite', value: status }))
    dispatch(generateChatBot(data))
  }

  const handleChangeTelegramBotStatus = (status) => {
    const data = {
      ...chatBotInputValues,
      settings: {
        ...chatSettings,
        telegramBotActive: status,
      },
    }

    handleDispatchChatSettings({ field: 'telegramBotActive', value: status })
    dispatch(generateChatBot(data))
  }

  const detectLocalAPIendPoint = () => {
    const currentProcess = process.env.REACT_APP_MODE
      ? process.env.REACT_APP_MODE
      : process.env.NODE_ENV

    //return url generated by NGROK on development  because telegram api works only https protocol, it not works for localhost
    if (currentProcess === 'development') {
      return `` //POINT YOUR NGROK URL
    } else {
      return Settings[currentProcess].server_url
    }
  }

  const handleActivateTelegramWebHook = async (
    botToken,
    telegramBotStatus,
    action = 'setWebhook',
    pendingUpdates = false
  ) => {
    const TELEGRAM_API_URL = `https://api.telegram.org/bot${botToken}`
    const URL = `${TELEGRAM_API_URL}/${action}?url=${detectLocalAPIendPoint()}/telegram/webhook&drop_pending_updates=${pendingUpdates}`
    try {
      const response = await axios.post(URL)
      if (response.data?.ok) {
        handleChangeTelegramBotStatus(telegramBotStatus)
      }
    } catch (err) {
      errorToast(<T keyName='eleo-telegram-bot-error'>Unexpected error occurred!</T>)
    }
  }

  const renderChatBotURL = () => {
    if (process.env.REACT_APP_MODE === 'develop' || process.env.NODE_ENV === 'development') {
      return `https://develop--chat-bot-widget.netlify.app`
    }

    if (process.env.NODE_ENV === 'production') {
      return `https://chatbot.eleo.ai`
    }
  }

  const generateWebsiteCode = useCallback(() => {
    return (
      <code className='!bg-transparent opacity-90'>
        {`
        <!-- Insert below code after body tag -->
        `}
        <br />
        {`<div 
          id="eleo-chat-bot" 
          position="${chatSettings.chatPlacement}" 
          chat-bot-name="${chatBotInputValues.name}" 
          chat-window-height="${chatSettings.windowHeight}"
          chat-window-width="${chatSettings.windowWidth}"
          widget="true"></div>`}{' '}
        <br />{' '}
        {`
        <!--Insert below code at the end of body tag -->
        `}
        <br />
        {`<script
           type="text/javascript"
           defer
           src="${renderChatBotURL()}/chat-bot-widget.js">
          </script>`}
      </code>
    )
  }, [chatSettings, chatBotInputValues])

  useEffect(() => {
    if (value) {
      handleAddWebsites()
    }
  }, [value])

  useEffect(() => {
    if (chatBotInputValues.selectedChatBot) {
      setValue(chatSettings.websites)
    }
  }, [chatBotInputValues.selectedChatBot])

  useEffect(() => {
    setBaseImage(chatSettings.chatIcon)
  }, [chatSettings.chatIcon])

  return (
    <div className='px-[16px] py-[30px]'>
      <div className='flex flex-wrap gap-[30px] lg:flex-nowrap'>
        <p className='text-brand-body whitespace-nowrap break-words text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-chat-bot-chat-icon'>Chat Icon</T>
        </p>
        <div className='w-full lg:w-1/4' data-tooltip-id='chatBotPanel-chatIcon'>
          <ImageDropZone
            id='dropzone-chat-icon'
            baseImage={baseImage}
            setBaseImage={setBaseImage}
            handleFileUpload={(e) => handleFileUpload(e, handleSetChatIcon, 'chatBot/chatIcon/')}
            classes='h-full w-full px-[10px] text-center !m-0'
          />
        </div>
        <div className='flex-1'>
          <div className='grid grid-cols-2  gap-[1rem] lg:gap-10'>
            {/* First grid item */}
            <div className='flex items-start gap-[30px]' data-tooltip-id='chatBotPanel-iconPlace'>
              <p className='text-brand-body whitespace-nowrap break-words text-[13px] font-medium capitalize leading-[1.2] '>
                <T keyName='eleo-chat-bot-icon-place'>icon place</T>
              </p>
              <ChatPositionSelector
                handleDispatchChatSettings={handleDispatchChatSettings}
                initialPosition={chatSettings.chatPlacement}
              />
            </div>
            {/* Second grid item */}
            <div>
              <div className='flex justify-end' data-tooltip-id='chatBotPanel-activeInactive'>
                <ChatStatusSwitcher
                  handleDispatchChatSettings={handleChangeChatBotStatus}
                  initialValue={chatSettings.activeOnRemoteWebsite}
                  field='activeOnRemoteWebsite'
                />
              </div>
            </div>
            {/* Third grid item */}
            <div
              className='flex w-full items-center gap-[10px]'
              data-tooltip-id='chatBotPanel-chatWindowWidth'
            >
              <p className='text-brand-body whitespace-nowrap break-words text-[13px] font-medium capitalize leading-[1.2] '>
                <T keyName='eleo-chat-bot-result-chat-window'>chat window</T>
                <br />
                <T keyName='eleo-chat-bot-result-chat-window-width'>width</T>
              </p>
              <Input
                className='placeholder-font-regular h-[48px]  w-full rounded-[8px]  text-[13px] placeholder-[#36363680]'
                placeholder='40px'
                value={chatSettings.windowWidth}
                onChange={(width) =>
                  handleDispatchChatSettings({ field: 'windowWidth', value: width })
                }
              />
            </div>
            {/* Fourth grid item */}
            <div
              className='flex w-full items-center gap-[10px]'
              data-tooltip-id='chatBotPanel-chatWindowHeight'
            >
              <p className='text-brand-body whitespace-nowrap break-words text-[13px] font-medium capitalize leading-[1.2] '>
                <T keyName='eleo-chat-bot-result-chat-window'>chat window</T>
                <br />
                <T keyName='eleo-chat-bot-result-chat-window-height'>height</T>
              </p>
              <Input
                className='placeholder-font-regular h-[48px]  w-full rounded-[8px]  text-[13px] placeholder-[#36363680]'
                placeholder='40px'
                value={chatSettings.windowHeight}
                onChange={(height) =>
                  handleDispatchChatSettings({ field: 'windowHeight', value: height })
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* where you want to integrate a chatbot */}
      <div
        className='mt-[20px] flex flex-wrap items-start gap-[10px] md:flex-nowrap'
        data-tooltip-id='chatBotPanel-websites'
      >
        <p className='text-brand-body max-w-[80px] break-words text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-chat-bot-code-website-list'>Website URL(s)</T>
          <br />
        </p>
        <div className='w-full'>
          <MultipleSelect
            value={chatSettings.websites.map((website) => {
              return {
                label: transformLinkToOrigin(website),
                value: transformLinkToOrigin(website),
              }
            })}
            setValue={setValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            placeHolder={t(
              'eleo-website-urls-placeholder-text',
              'Website URL(s) where you want to integrate a chatbot'
            )}
          />
        </div>
      </div>

      <div
        className='mt-[20px] flex flex-wrap items-start gap-[10px] md:flex-nowrap'
        data-tooltip-id='chatBotPanel-codeForWebsite'
      >
        <p className='text-brand-body max-w-[80px] break-words text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-chat-bot-code-for-the-website'>Code for the website</T>
          <br />
        </p>
        <div
          className='bg-brand-grey placeholder-font-regular h-[100px] w-full overflow-y-scroll rounded-[8px] border px-[5px] py-[10px] text-[13px] placeholder-[#36363680]'
          ref={websiteCodeRef}
        >
          {generateWebsiteCode()}
        </div>
        <Button
          color='dark'
          className='h-[48px] px-[13px]'
          data-tooltip-id='chatBotPanel-copyCode'
          onClick={() =>
            CopyToClipBoard(
              websiteCodeRef?.current.innerText,
              <T keyName='eleo-text-copied-success-message'>
                Text successfully copied on your clipboard
              </T>
            )
          }
        >
          <p className='whitespace-nowrap text-[15px] uppercase'>
            <T keyName='eleo-chat-bot-result-copy-code'>Copy Code</T>
          </p>
        </Button>
      </div>
      <div
        className='mt-[20px] flex w-full items-center gap-[10px]'
        data-tooltip-id='chatBotPanel-telegramToken'
      >
        <p className='text-brand-body min-w-[80px] max-w-[80px] flex-shrink-0 flex-grow-0 text-[13px] font-medium capitalize leading-[1.2]'>
          <T keyName='eleo-chat-bot-telegram-bot-token'>Telegram Bot Token</T>
        </p>
        <div className='flex w-full flex-wrap items-center justify-between gap-[10px] md:flex-nowrap'>
          <Input
            className='placeholder-font-regular h-[48px] flex-1 rounded-[8px] bg-[#36363622] px-[10px] text-[13px]  placeholder-[#36363680] disabled:opacity-80'
            placeholder={t('eleo-chat-bot-telegram-placeholder', 'Your Telegram Bot URL')}
            value={chatSettings.telegramToken}
            onChange={(telegramToken) =>
              handleDispatchChatSettings({ field: 'telegramToken', value: telegramToken })
            }
            disabled={chatSettings.telegramBotActive}
          />
          <Button
            color='dark'
            className='h-[48px] px-[13px]'
            disabled={!chatSettings.telegramToken.length || chatSettings.telegramBotActive}
            onClick={() =>
              handleActivateTelegramWebHook(chatSettings.telegramToken, true, 'setWebhook', true)
            }
            data-tooltip-id='chatBotPanel-activate'
          >
            <p className='whitespace-nowrap text-[15px] uppercase'>
              <T keyName='eleo-chat-bot-activate-telegram-bot'>Activate</T>
            </p>
          </Button>
          <Button
            color='pink'
            className='h-[48px] px-[13px]'
            disabled={!chatSettings.telegramToken.length || !chatSettings.telegramBotActive}
            onClick={() =>
              handleActivateTelegramWebHook(
                chatSettings.telegramToken,
                false,
                'deleteWebhook',
                true
              )
            }
            data-tooltip-id='chatBotPanel-turnOff'
          >
            <p className='whitespace-nowrap text-[15px] uppercase'>
              <T keyName='eleo-chat-bot-turnoff-telegram-bot'>Turn Off</T>
            </p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChatSettings
