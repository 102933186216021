import React from 'react'
import { Input, Button } from 'ui'
import { T } from '@tolgee/react'
import WriterHeader from 'toolComponents/writer/WriterHeader'
import ChatStatusSwitcher from './chatStatusSwitcher'
import { useDispatch, useSelector } from 'react-redux'
import {
  generateChatBot,
  SelectChatBotInputValues,
  SelectChatBotSettings,
  setChatBotSettings,
} from 'store/account/chatBot'
import { CopyToClipBoard } from 'helpers/clipboard'

const GeneratedLinks = () => {
  const dispatch = useDispatch()
  const chatSettings = useSelector(SelectChatBotSettings)

  const chatInputData = useSelector(SelectChatBotInputValues)

  const handleChangeChatBotStatus = (status) => {
    const data = {
      chatBotId: chatInputData.selectedChatBot,
      ...chatInputData,
      settings: {
        ...chatSettings,
        activeOnEleo: status,
      },
    }

    dispatch(setChatBotSettings({ field: 'activeOnEleo', value: status }))
    dispatch(generateChatBot(data))
  }

  return (
    <div className='flex flex-col'>
      <hr className='block lg:hidden' />
      <WriterHeader
        historyIsVisible={false}
        showHistoryIcon={false}
        showHelpButton={false}
        headerTitle='Your Chat Bot On Eleo'
        headerContainerClasses='!py-[8px]'
      />
      <div className='px-[16px] py-[30px]'>
        <div className='item-center flex  w-full flex-wrap gap-[15px] lg:justify-between lg:gap-[10px] min-[1200px]:flex-nowrap'>
          <div className='flex w-full items-center gap-[10px] min-[1200px]:w-[42%]'>
            <p className='text-brand-body min-w-[65px] max-w-[65px] flex-shrink-0 flex-grow-0 text-[13px] font-medium capitalize leading-[1.2]'>
              <T keyName='eleo-chat-bot-result-link-to-your-chat'>Link to your chat</T>
            </p>
            <Input
              className='placeholder-font-regular h-[48px] w-full rounded-[8px] bg-[#36363622] px-[3px] text-[13px] placeholder-[#36363680]'
              value={
                chatInputData.name ? `${window.location.origin}/chat/${chatInputData.name}` : ''
              }
              placeholder=''
              disabled
            />
          </div>
          <div className='w-1/4 min-[1200px]:w-auto'>
            <Button
              color='dark'
              className='h-[48px] px-[10px] lg:px-[13px]'
              data-tooltip-id='chatBotPanel-copyLink'
              disabled={!chatInputData.name}
              onClick={() =>
                CopyToClipBoard(
                  `${window.location.origin}/chat/${chatInputData.name}`,
                  <T keyName='eleo-text-copied-success-message'>
                    Text successfully copied on your clipboard
                  </T>
                )
              }
            >
              <p className='whitespace-nowrap text-[13px] uppercase lg:text-[15px]'>
                <T keyName='eleo-chat-bot-result-copy-link'>Copy link</T>
              </p>
            </Button>
          </div>
          <div className='w-1/4 min-[1200px]:w-auto'>
            <Button
              color='dark'
              className='h-[48px] px-[10px] lg:px-[13px]'
              data-tooltip-id='chatBotPanel-openChat'
              disabled={!chatInputData.name}
              href={`${window.location.origin}/chat/${chatInputData.name}`}
              target='_blank'
            >
              <p className='whitespace-nowrap text-[13px] uppercase lg:text-[15px]'>
                <T keyName='eleo-chat-bot-result-open-chat'>Open Chat</T>
              </p>
            </Button>
          </div>
          <div
            className='ml-[10px] w-1/4 lg:ml-0 lg:w-auto'
            data-tooltip-id='chatBotPanel-activeInactive'
          >
            <ChatStatusSwitcher
              handleDispatchChatSettings={handleChangeChatBotStatus}
              initialValue={chatSettings.activeOnEleo}
              field='activeOnEleo'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneratedLinks
