import { ToolGeneratorLayoutPrimary } from 'components/lib'
import React from 'react'
import WriterHeader from 'toolComponents/writer/WriterHeader'
import ChatActions from './chatActions'
import ChatOptions from './chatOptions'

const ChatGenerator = ({
  isShowAdvanced,
  setIsShowAdvanced,
  storyTemplateOptions,
  handleGenerateChatBot,
  handleDeleteChatBot,
  handleFileUpload,
  documentContext,
}) => {
  return (
    <ToolGeneratorLayoutPrimary
      topContent={
        <WriterHeader
          historyIsVisible={false}
          showHistoryIcon={false}
          headerTitle='Chat Bot'
          toolName='profile-chat-bot'
        />
      }
      options={
        <div>
          <ChatOptions
            isShowAdvanced={isShowAdvanced}
            setIsShowAdvanced={setIsShowAdvanced}
            storyTemplateOptions={storyTemplateOptions}
            handleDeleteChatBot={handleDeleteChatBot}
            handleFileUpload={handleFileUpload}
            documentContext={documentContext}
          />
        </div>
      }
      actions={<ChatActions handleGenerateChatBot={handleGenerateChatBot} />}
    ></ToolGeneratorLayoutPrimary>
  )
}

export default ChatGenerator
