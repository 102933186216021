import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import { Animate, AuthContext, ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import CategoriesSwitch from 'toolComponents/chat-bot/library/CategoriesSwitch'
import { ChatbotsCarousel } from 'toolComponents/chat-bot/library/ChatbotsCarousel'
import { ChatbotsList } from 'toolComponents/chat-bot/library/ChatbotsList'
import { useDebounce } from 'use-debounce'

export const ChatbotLibraryAuthor = () => {
  const context = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const [items, setItems] = useState([])
  const [recommendations, setRecommendations] = useState([])
  const [authorData, setAuthorData] = useState({ description: '' })
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 1000)
  const { t } = useTranslate()
  const params = useSearchParams()[0]
  let { author } = useParams()
  if (!author) author = authContext.user.accounts[0].name

  // const authorData = useAPI(`/api/chat-bot/library/author/recommendations/${author}`)

  const fetchItems = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=40&filter=${debounceFilter}&author=${author}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [debounceFilter, params, author])

  const fetchRecommendations = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/author/recommendations/${author}?filter=${debounceFilter}&author=${author}${category ? `&category=${category}` : ''}`
      )
      setRecommendations(res.data.data.chatbots)
      setAuthorData({ description: res.data.data.description })
    } catch (err) {
      context.handleError(err)
    }
  }, [debounceFilter, params, author])

  const fetchAll = useCallback(() => {
    fetchItems()
    fetchRecommendations()
  }, [fetchItems, fetchRecommendations])

  useEffect(() => {
    fetchAll()
  }, [fetchAll])

  const title = t('authors-chatbots-title', 'Chatbot author: {author}', { author: author })

  return (
    <Animate>
      <div className='flex h-full flex-col'>
        <CategoriesSwitch filter={filter} setFilter={setFilter} />
        <ChatbotsList items={items} title={title} fetchItems={fetchAll} />
        <div className='p-4'>
          <ChatbotsCarousel
            items={recommendations}
            title={
              <T
                keyName='eleo-recommended-by-title'
                defaultValue='Recommended by {author}'
                params={{ author: author }}
              />
            }
            refetchData={fetchAll}
          />

          {authorData.description}
        </div>
      </div>
    </Animate>
  )
}

export default ChatbotLibraryAuthor
