import { ContentCopy, Download, MoreVert, Share, VolumeOff, VolumeUp } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { downloadAudioFromStream } from 'helpers'
import { Sparkle } from 'phosphor-react'
import { useState } from 'react'
import { Microbutton } from 'ui'

export function MessageOptions({
  regenerateResponse,
  index,
  message,
  showShareModal,
  widget,
  handleTTS,
  width,
  isLoading,
  ttsIndex,
  handleCopy,
}) {
  const { t } = useTranslate()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'language-popover' : undefined

  if (width < 768) {
    return (
      <div
        className={classNames(
          '-mr-[20px] mt-[6px] flex justify-end rounded-b-lg',
          isLoading && 'invisible'
        )}
      >
        <div className='flex gap-[6px]'>
          {/* Options button */}
          {widget ? (
            <Microbutton
              variant='plain'
              icon={<ContentCopy fontSize='inherit' />}
              className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
              onClick={() => {
                handleClose()
                handleCopy()
              }}
            />
          ) : (
            <>
              <Microbutton
                icon={<MoreVert fontSize='inherit' />}
                variant='plain'
                onClick={handleClick}
                className='hover:bg-brand-violet/10 size-[40px]'
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <div className='flex gap-2 p-2 py-3'>
                  {/* Regenerate */}
                  <Microbutton
                    iconPlacement='left'
                    icon={<Sparkle weight='fill' />}
                    variant='plain'
                    text={t('eleo-chat-regenerate', 'regenerate')}
                    className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                    onClick={() => {
                      handleClose()
                      regenerateResponse(index - 1)
                    }}
                  />

                  {/* Clipboard */}
                  <Microbutton
                    variant='plain'
                    icon={<ContentCopy fontSize='inherit' />}
                    className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                    onClick={() => {
                      handleClose()
                      handleCopy()
                    }}
                  />

                  {/* Share */}
                  <Microbutton
                    variant='plain'
                    icon={<Share fontSize='inherit' />}
                    className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                    onClick={() => {
                      handleClose()
                      showShareModal(message.content)
                    }}
                  />

                  {/* Dowload TTS */}

                  <Microbutton
                    iconPlacement='right'
                    variant='plain'
                    text='MP3'
                    icon={<Download fontSize='inherit' />}
                    className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                    onClick={() => {
                      handleClose()
                      downloadAudioFromStream(message.content, widget)
                    }}
                  />
                </div>
              </Popover>
            </>
          )}

          {/* TTS */}
          {!widget ? (
            <Microbutton
              variant='plain'
              icon={
                ttsIndex === index ? (
                  <VolumeOff fontSize='inherit' />
                ) : (
                  <VolumeUp fontSize='inherit' />
                )
              }
              className='hover:bg-brand-violet/10 text-brand-text-secondary/80 size-[40px] uppercase'
              onClick={() => handleTTS(message.content, index)}
            />
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        '-mr-[20px] mt-[6px] flex justify-end rounded-b-lg',
        isLoading && 'invisible'
      )}
    >
      <div className='flex gap-1'>
        <div className='flex gap-[6px] opacity-0 transition-opacity group-hover:opacity-100'>
          {/* Regenerate */}
          {!widget && (
            <>
              <Microbutton
                iconPlacement='left'
                icon={<Sparkle weight='fill' />}
                variant='plain'
                text={t('eleo-chat-regenerate', 'regenerate')}
                className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                onClick={() => regenerateResponse(index - 1)}
              />

              <div className='my-[5px] border-r' />
            </>
          )}

          <div className='flex'>
            {/* Clipboard */}
            <Microbutton
              variant='plain'
              icon={<ContentCopy fontSize='inherit' />}
              className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
              onClick={handleCopy}
            />

            {/* Share */}
            {!widget && (
              <Microbutton
                variant='plain'
                icon={<Share fontSize='inherit' />}
                className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
                onClick={() => showShareModal(message.content)}
              />
            )}
          </div>

          {!widget ? <div className='my-[5px] border-r' /> : null}
          {/* Dowload TTS */}

          <Microbutton
            iconPlacement='right'
            variant='plain'
            text='MP3'
            icon={<Download fontSize='inherit' />}
            className='hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase'
            onClick={() => downloadAudioFromStream(message.content, widget)}
          />
        </div>

        {/* TTS */}

        <Microbutton
          variant='plain'
          icon={
            ttsIndex === index ? <VolumeOff fontSize='inherit' /> : <VolumeUp fontSize='inherit' />
          }
          className={classNames(
            'hover:bg-brand-violet/10 text-brand-text-secondary/80 uppercase',
            ttsIndex !== index && 'opacity-0 transition-opacity group-hover:opacity-100'
          )}
          onClick={() => handleTTS(message.content, index)}
        />
      </div>
    </div>
  )
}
