import { T } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ViewContext } from 'components/lib'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import LibraryItem from './LibraryItem'
import { useWindowDimensions } from 'components/hooks/window'

export const ChatbotsCarousel = ({ items, title, refetchData, seeMoreUrl }) => {
  const { width } = useWindowDimensions()
  const cardWidth = useMemo(() => (width > 1280 ? 300 : width > 768 ? 210 : 190), [width])

  const context = useContext(ViewContext)
  const rowRef = useRef()
  const [itemsInRow, setItemsInRow] = useState(1)
  const useCarousel = itemsInRow < items.length && itemsInRow > 1

  useEffect(() => {
    if (rowRef.current) {
      setItemsInRow(
        rowRef.current.clientWidth ? Math.floor(rowRef.current.clientWidth / (cardWidth + 40)) : 1
      )
    }
  }, [width, cardWidth])

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: Math.min(items.length, itemsInRow),
    slidesToScroll: 1,
    // centerMode: true,
    className: 'center',
    arrows: true,
  }

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  return (
    <div className='flex h-full flex-col'>
      <h3 className='pb-4 font-bold'>{title}</h3>
      <div
        ref={rowRef}
        className={classNames(
          'slider-container relative',
          useCarousel ? 'px-8' : 'flex gap-8 overflow-x-auto p-4 md:pl-[3.25rem]'
        )}
      >
        {useCarousel ? (
          <>
            <div className='from-brand-gray-bg absolute left-8 z-[99999] h-full w-8 bg-gradient-to-r  to-transparent'></div>
            <div className='from-brand-gray-bg absolute right-8 z-[99999] h-full w-24 bg-gradient-to-l  to-transparent'></div>
            <Slider {...settings}>
              {items.map((item) => {
                return (
                  <div className='p-5' key={item.id}>
                    <LibraryItem
                      key={item.id}
                      item={item}
                      handleLikeChatbot={handleLikeChatbot}
                      handleRecommendChatbot={handleRecommendChatbot}
                    />
                  </div>
                )
              })}
            </Slider>
          </>
        ) : (
          items.map((item) => {
            return (
              <LibraryItem
                key={item.id}
                item={item}
                handleLikeChatbot={handleLikeChatbot}
                handleRecommendChatbot={handleRecommendChatbot}
              />
            )
          })
        )}
      </div>

      {seeMoreUrl && (
        <h4 className='flex justify-end'>
          <Link to={seeMoreUrl}>
            <T keyName={'eleo-see-more'}>See more</T>
          </Link>
        </h4>
      )}
    </div>
  )
}
